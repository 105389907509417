import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Logo from '~/images/logo.svg';

import { Container } from './styles';

const Header = () => {
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsChrome(
        !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
      );
    }
  }, []);

  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(
    graphql`
      query MySiteData {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <Container id="header">
      <Link to="/">
        <img src={Logo} alt={title} />
      </Link>
      <ul>
        {isChrome && (
          <li className="chrome">
            <OutboundLink
              target="_blank"
              href="https://chrome.google.com/webstore/detail/outside-simulator/lemblgpmokabdmjphfehokigmpeobefc"
            >
              Get the Chrome Extension
            </OutboundLink>
          </li>
        )}
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </Container>
  );
};

export default Header;
