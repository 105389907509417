import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

export const Container = styled(BackgroundImage)`
  min-height: 100vh;
  max-height: 100vh;

  &:before,
  &:after {
    position: fixed !important;
  }

  &:before {
    opacity: ${({ opacity }) => opacity} !important;
  }
`

export const Main = styled.main`
  padding: 50px;
  z-index: 1;
  position: relative;

  @media (max-width: 767px) {
    padding: 20px;
  }
`

export const BackgroundFade = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  opacity: 0.99;
  background: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01));

  &[style] {
    background: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01));
  }
`
