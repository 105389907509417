import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  position: fixed;
  width: 100%;
  z-index: 2;

  @media (max-width: 767px) {
    padding: 10px 20px;
  }

  a {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.67);
    img {
      @media (max-width: 767px) {
        max-width: 200px;
      }
    }
  }

  ul {
    display: flex;
    margin: 0;

    li {
      margin: 0 5px;
      text-transform: uppercase;
      padding: 5px 10px;
      position: relative;
      font-family: 'Fjalla One', sans-serif;

      &.chrome {
        background: #ffffff1f;
        padding: 5px 10px;
        border-radius: 5px;

        &:after {
          content: 'NEW';
          position: absolute;
          height: 9px;
          color: #fff;
          background: red;
          top: -10px;
          right: -10px;
          font-size: 10px;
          padding: 5px;
          line-height: 10px;
          font-weight: bold;
          border-radius: 4px;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
`;
