import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { Helmet } from "react-helmet"

import GlobalStyles from "~/styles/global"
import SEO from "../seo"

import Header from "../Header"
import { Container, Main, BackgroundFade } from "./styles"

const Layout = ({ children }) => {
  const [opacity, setOpacity] = useState(0)

  const {
    desktop: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background-image.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    if (window.scrollY > window.innerHeight / 2) {
      document.querySelector("#foreground").style.background =
        "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8))"

      document.querySelector("#header").style.background =
        "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8))"
    }
  }, [])

  useScrollPosition(({ lastPos, currPos }) => {
    var opac =
      1 - (window.innerHeight / 2 / Math.abs(currPos.y) / 10).toFixed(3)
    if (currPos.y !== 0) {
      if (opac !== opacity && opac < 0.8) {
        // setOpacity(opac)
        document.querySelector("#foreground").style.background =
          "linear-gradient(rgba(0,0,0, " +
          opac +
          "), rgba(0,0,0, " +
          opac +
          "))"

        document.querySelector("#header").style.background =
          "linear-gradient(rgba(0,0,0, " +
          opac +
          "), rgba(0,0,0, " +
          opac +
          "))"
      }
    }
  })

  return (
    <>
      <Container fluid={fluid} backgroundColor={"#040e18"}>
        <BackgroundFade
          id="foreground"
          backgroundImage={fluid.srcWebp}
          style={{
            background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))",
          }}
        />
        <GlobalStyles />
        <Header />
        <Main>{children}</Main>
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
